import React, { useContext, useEffect } from 'react';
import styles from './Mode.module.scss';
import GameStateContext from './GameStateContext';
import { switchFullScreen } from './util';

const Mode = () => {
  const gameState = useContext(GameStateContext);
  const [stats, setStats] = gameState.statsState;

  const onModeClick = () => {
    setStats(prevStats => ({
      ...prevStats,
      mode: false,
    }));
    switchFullScreen(false);
  }

  const handleKeyDown = event => {
    if (event.key.toUpperCase() === 'M') {
      onModeClick();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    stats.mode && (
      <div>
        <div className={styles.mode}>
          <span>{stats.score}</span>
        </div>
        <div className={styles.controls}>
          <span className={styles.control} onClick={onModeClick}><span className={styles.underline}>M</span>ODE</span>
        </div>
      </div>
    )
  );
};

export default Mode;

