import { useEffect } from 'react';

export function useGameLifecycle(statsState) { //, sounds) {
  const [stats, setStats] = statsState;

  const onGameStart = () => {
    if (stats.isStarted || stats.isGameover) return;

    setStats(prevStats => ({
      ...prevStats,
      isStarted: true,
    }));

    //alert('start'); // XXX
    //sounds.theme.play();
  };

  const onGameOver = () => {
    if (stats.isStarted && stats.isGameover) {
      setStats(prevStats => ({
        ...prevStats,
        isStarted: false,
      }));

      //alert('game over'); // XXX
      //sounds.theme.stop();
    }
  };

  useEffect(() => {
    if (!stats.isStarted) {
      onGameStart();
    }
    if (stats.isStarted && stats.isGameover) {
      onGameOver();
    }
  }, [stats]);
}
