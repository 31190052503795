export const switchFullScreen = (isFullScreen) => {
    console.log(`Switching full screen: ${isFullScreen}`);
    if (isFullScreen) {
        // Request to enter full screen
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) { /* Firefox */
            document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) { /* IE/Edge */
            document.documentElement.msRequestFullscreen();
        }
    } else {
        // Exit full screen
        if (document.fullscreenElement && document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozFullScreenElement && document.mozCancelFullScreen) { /* Firefox */
            document.mozCancelFullScreen();
        } else if (document.webkitFullscreenElement && document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
        } else if (document.msFullscreenElement && document.msExitFullscreen) { /* IE/Edge */
            document.msExitFullscreen();
        }
    }
}