import styles from './Stats.module.scss';
import GameStateContext from './GameStateContext';
import React, { useContext } from 'react';
import { getLevelInfo } from './objects/levelEngine';
import Controls from './Controls';
import useResponsiveSize from './useResponsiveSize';

const Stats = () => {
  const gameState = useContext(GameStateContext);
  const [stats] = gameState.statsState;
  const levelInfo = getLevelInfo(stats.score, stats.difficulty);
  const statsSize = useResponsiveSize();

  return (
    !stats.mode && (
      <div className={styles.statsContainer} style={{ width: statsSize.width * 2 }}>
        <div className={styles.level}>
          Level {levelInfo.level}
          <span className={styles.difficulty}>{stats.difficulty}</span>
        </div>
        <div className={styles.controls}>
          <Controls />
        </div>
        <div className={styles.score}>Score: {stats.score}</div>
      </div>
    )
  );
};

export default Stats;