import Board from './Board';
import Field from './Field';
import Stats from './Stats';
import React from 'react';
import { createRoot } from 'react-dom/client';
import styles from './App.module.scss';
import { GameStateProvider } from './GameStateContext';
import { TourProvider } from '@reactour/tour';
import CloseTourButton from './CloseTourButton';

// To import CSS that won't get renamed
import {} from './App.scss';
import { SoundProvider } from './SoundContext';

const App = () => {
  // Tour docs: https://www.skypack.dev/view/@reactour/tour
  const steps = [
    {
      selector: '.tour-first-step',
      content: (
        <div>
          <div className={styles.tourTitle}>Quick game tour (1/3)</div>
          <div>Balls are moving from left.</div>
        </div>
      ),
    },
    {
      selector: '.tour-second-step',
      content: (
        <div>
          <div className={styles.tourTitle}>Make to ten (2/3)</div>
          <div>
            <u>Click</u> in the area, so the number of balls in one line is 10.
            Correct lines will disappear{' '}
            <span className={styles.tourNote}>
              (and will fix your failures, one by one...)
            </span>
          </div>
        </div>
      ),
    },
    {
      selector: '.tour-third-step',
      content: (
        <div>
          <div className={styles.tourTitle}>Game is over when.. (3/3)</div>
          <div>
            ...the number of balls in the last <u>column</u> is 10. Don&apos;t
            let it happen.
            <span className={styles.tourNote}>Good luck!</span>
          </div>
          <div className={styles.tourClose}>
            <CloseTourButton />
          </div>
        </div>
      ),
    }, // ...
  ];

  return (
    <SoundProvider>
      <GameStateProvider>
        <TourProvider
          className={styles.tour}
          steps={steps}
          showBadge={false}
          showCloseButton={
            true
          } /* MUST BE TRUE - there were bugs with closing tour via CloseTourButton */
          prevButton={({ currentStep, setCurrentStep }) => {
            const first = currentStep === 0;
            const f = () => {
              if (!first) {
                setCurrentStep(() => currentStep - 1)
              }
            };
            // Leave onClick handlers for both button and div. The div makes the clicking area bigger.
            // And you never know which device is going to click on div, and which on the button.
            return (
              <button
                style={{
                  display: 'block',
                  border: '0px',
                  background: 'none',
                  padding: '0',
                  cursor: 'pointer',
                  position: 'relative',
                }}
                onClick={f}
              >
                <svg viewBox="0 0 18.4 14.4" style={{color: first ? "#5a189a" : "#ffffff", width: "16px", height: "12px", flex: "0 0 16px"}}>
                  <path d="M1.4 7.2h16M7.6 1L1.4 7.2l6.2 6.2" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10"></path>
                </svg>
                <div style={{
                  position: 'absolute',
                  // border: 'solid 1px yellow',
                  width: '50px',
                  height: '50px',
                  left: '-17px',
                  top: '-20px',
                  cursor: 'pointer',
                }}
                onClick={f}>
                </div>
              </button>
            )
          }}
          nextButton={({ currentStep, setCurrentStep, steps }) => {
            const last = currentStep === steps.length - 1;
            const f = () => {
              if (!last) {
                setCurrentStep(() => currentStep + 1);
              }
            };
            return (
              <button
                style={{
                  display: 'block',
                  border: '0px',
                  background: 'none',
                  padding: '0',
                  cursor: 'pointer',
                  position: 'relative',
                }}
                onClick={f}
              >
                <svg viewBox="0 0 18.4 14.4" style={{ color: last ? "#5a189a" : "#ffffff", width: "16px", height: "12px", flex: "0 0 16px"}}>
                  <path d="M17 7.2H1M10.8 1L17 7.2l-6.2 6.2" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10"></path>
                </svg>
                <div style={{
                  position: 'absolute',
                  // border: 'solid 1px yellow',
                  width: '50px',
                  height: '50px',
                  left: '-17px',
                  top: '-20px',
                  cursor: 'pointer',
                }}
                onClick={f}>
                </div>
              </button>
            )
          }}
        >
          <div className={styles.container}>
            <Field />
            <Board />
          </div>
          <div className={styles.container2}>
            <Stats />
          </div>
        </TourProvider>
      </GameStateProvider>
    </SoundProvider>
  );
};

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
