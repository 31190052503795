import {
  EMPTY,
  BALL_TYPE_COMPUTER,
  BALL_TYPE_HUMAN,
  BALL_FLAG_DISAPPEARING,
  BALL_FLAG_MISTAKE,
  BALL_FLAG_MOVING,
} from './constants';
import { getLevelInfo } from './levelEngine';
import { playSound } from '../soundsUtil';

export function isGameover(grid) {
  for (let j = 0; j < 10; j++) {
    // if it's empty then it's not a game over
    if (grid[j][19] === EMPTY) return false;

    // if there is a ball, but no mistake flag set it can be disappearing
    // return if there is no mistake flag
    if ((grid[j][19] & BALL_FLAG_DISAPPEARING) !== 0) return false;

    // if there is a ball, but no mistake flag set it can be disappearing
    // return if there is no mistake flag
    if ((grid[j][19] & BALL_FLAG_MOVING) !== 0) return false;
  }
  return true;
}

// There are 20 ticks in 1 second (standard speed). Variable "cnt" keeps the count of ticks.
export function updateOnTick(cnt, grid, levelInfo, sounds, tick) {
  // Do not bother for each tick for now, update state every second if speedfactor is 20.
  // 1/4 less than a second if speedfactor is 15.
  // 1/2 less than a second if speed factor is 10.
  if (cnt % levelInfo.speedFactor !== 0) {
    return grid;
  }

  // Skip first second
  const t = Math.floor(cnt / 20);
  //if (t <= 0) return grid;

  // Play tick sound
  console.log(`Playing tick sound... ${t}`);
  if (tick.value) {
    console.log('Playing tick1');
    playSound(sounds.sprite, 'tick1', 0.1);
  } else {
    console.log('Playing tick2');
    playSound(sounds.sprite, 'tick2', 0.1);
  }
  tick.value = !tick.value;

  const newGrid = grid.map(row => [...row]);

  // Assumes there is non-interrupted line of balls. Moves this line by moving ball
  // from left side to the right side if there is a space on the right.
  for (let j = 0; j < newGrid.length; j++) {
    let p1 = 0;
    const row = newGrid[j];
    let clearBallMovingFlagForEntireRow = false;

    while (p1 < row.length) {
      let p2 = p1;
      // In case of ball we can move
      if ((row[p1] & BALL_FLAG_MOVING) !== 0) {
        // Look for position to move the first ball of the line. But first, scroll to the
        // last position in the line.
        while (p2 < row.length - 1 && (row[p2] & BALL_FLAG_MOVING) !== 0) {
          p2++;
        }
        // If there is a space to the right, swap elements
        if (row[p2] === EMPTY) {
          row[p2] = row[p1];
          row[p1] = EMPTY;
        } else {
          // If no space, the ball is not moving anymore, clear the flag
          clearBallMovingFlagForEntireRow = true;
        }
        // Update the pointer
        p1 = p2 + 2;
      } else {
        p1++;
      }
    }

    if (clearBallMovingFlagForEntireRow) {
      // We always clear here when an element makes its own way to the end of line.
      // So we can definitely say if there is a ball, it's mistake now. So update mistake flag.
      for (let i = 0; i < row.length; i++) {
        row[i] = row[i] & ~BALL_FLAG_MOVING;
        const ballType = row[i] & 0b00001111;
        if (ballType !== EMPTY) {
          row[i] = row[i] | BALL_FLAG_MISTAKE;
        }
      }
    }
  }

  return addMoreBallsIfNeeded(newGrid, levelInfo);
}

export function handleClick(j, i, grid, statsState, sounds) {
  if (j >= 0 && i >= 0 && j < grid.length && i < grid[0].length) {
    const [stats, setStats] = statsState;
    if (stats.isPaused || stats.isTour || stats.isGameover) return grid;

    // Allow to fill to the right from click only if the space to the right is empty
    const hasNonZeroValuesAfterIndex = grid[j].some(
      (value, index) => index > (i || 0) && value !== EMPTY
    );
    if (hasNonZeroValuesAfterIndex) return grid;

    const newGrid = grid.map(row => [...row]);
    const row = newGrid[j];

    // Fill to the right from click
    for (let col = i; col < row.length; col++) {
      row[col] = BALL_TYPE_HUMAN;
    }

    // Move ones from the beginning to the clicked column
    let p1 = 0;
    let p2 = i - 1;
    while (p1 < p2) {
      console.log(row);
      if (row[p1] !== EMPTY) {
        // Move pointer if there is any items in front (edge-case)
        while (row[p2] !== EMPTY) {
          p2--;
        }

        if (p1 < p2) {
          const tmp = row[p2];
          row[p2] = row[p1] & ~BALL_FLAG_MOVING; // Clear moving flag along the way
          row[p1] = tmp;
          p2--;
        }
      }
      p1++;
    }

    // Update disappearing flag - only disappears in case of 00000000001111111111
    // For other combinations like too many elements or too few it is considered as mistake
    let cntHumanBallCount = 0;
    const isDisappearing = row.every((value, index) => {
      if (value === BALL_TYPE_HUMAN) cntHumanBallCount++;
      return (index >= 10 && value !== EMPTY) || (index < 10 && value == EMPTY);
    });

    if (isDisappearing && cntHumanBallCount !== 10) {
      for (let col = 10; col < 20; col++) {
        row[col] = row[col] | BALL_FLAG_DISAPPEARING;
      }
      const mistakeFound = removeOneMistakeIfPossible(newGrid);
      if (!mistakeFound) {
        // If no mistake, play fancier success sound every 10 points
        if ((stats.score + 1) % 10 === 0) {
          sounds.sprite.play('onten');
          sounds.sprite.play('success');
        } else {
          sounds.sprite.play('success');
        }

        // No mistake found - it means we can update score
        setStats(prevStats => ({
          ...prevStats,
          score: prevStats.score + 1,
        }));
      } else {
        sounds.sprite.play('success');
      }
    } else {
      for (let col = 0; col < 20; col++) {
        if (row[col] != EMPTY) {
          row[col] = row[col] | BALL_FLAG_MISTAKE;
        }
      }
      sounds.sprite.play('failure');
    }

    const levelInfo = getLevelInfo(stats.score, stats.difficulty);
    return addMoreBallsIfNeeded(newGrid, levelInfo);
  } else {
    return grid;
  }
}

// Getting called after certain interval (500 msec) from UI.
// Our goal here is to replace disappearing elements with empty.
// The reason we cannot remove elements right away is animation, so we do it in two steps:
//
// 1. Mark as disappearing, but not in this method (and animate in UI)
// 2. Remove all disappearing after 500 msec or so (this method)
export function removeDisappearingIfNeeded(grid) {
  const newGrid = grid.map(row => [...row]);

  for (let j = 0; j < newGrid.length; j++) {
    for (let i = 0; i < newGrid[j].length; i++) {
      if ((newGrid[j][i] & BALL_FLAG_DISAPPEARING) !== 0) {
        newGrid[j][i] = EMPTY;
      }
    }
  }

  return newGrid;
}

const removeOneMistakeIfPossible = grid => {
  let k = -1; // Row with the max number of mistakes
  let maxCnt = 0;

  for (let j = 0; j < grid.length; j++) {
    let cnt = 0; // Mistake counter
    for (let i = 0; i < grid[0].length; i++) {
      if ((grid[j][i] & BALL_FLAG_MISTAKE) !== 0) cnt++;
    }

    if (cnt > maxCnt) {
      maxCnt = cnt;
      k = j;
    }
  }

  // If row with mistake found, remove one mistake and add disappearing flag
  if (k >= 0) {
    for (let i = 0; i < grid[0].length; i++) {
      if ((grid[k][i] & BALL_FLAG_MISTAKE) !== 0) {
        grid[k][i] = grid[k][i] & ~BALL_FLAG_MISTAKE;
        grid[k][i] = grid[k][i] | BALL_FLAG_DISAPPEARING;
        break;
      }
    }
  }

  // Return true if one mistake was actually removed
  return k >= 0;
};

const addMoreBallsIfNeeded = (grid, levelInfo) => {
  let numberOfMovingBalls = 0;
  const freeRowsIndicies = [];

  // calculate variables we need for the decision
  for (let j = 0; j < grid.length; j++) {
    const row = grid[j];
    let currentRowNumberOfMovingBalls = 0;
    let currentRowNumberOfEmptyCells = 0;

    for (let i = 0; i < row.length; i++) {
      const currentValue = row[i];
      if ((currentValue & BALL_FLAG_MOVING) !== 0) {
        currentRowNumberOfMovingBalls++;
      }
      // IMPORTANT: do not check for disappearing condition below, let them disappear!
      // There was a bug when some of the balls are disappearing, and new have been added
      // into this row. So disappearing haven't disappeared completely, and new were moving,
      // this fucked up the game state.
      if (currentValue === EMPTY) {
        currentRowNumberOfEmptyCells++;
      }
    }
    numberOfMovingBalls += currentRowNumberOfMovingBalls;

    // Update the list of available lines
    if (currentRowNumberOfEmptyCells === row.length) {
      freeRowsIndicies.push(j);
    }
  }

  // Add more balls if needed
  if (numberOfMovingBalls === 0 && freeRowsIndicies.length > 0) {
    // Get the number of elements we gonna add
    const randomNumberOfElements =
      1 +
      Math.floor(
        Math.random() *
          Math.min(levelInfo.maxNumberOfElements, freeRowsIndicies.length)
      );

    // Add these elements
    for (let n = 0; n < randomNumberOfElements; n++) {
      const randomIndex = Math.floor(Math.random() * freeRowsIndicies.length);
      const randomSize = 1 + Math.floor(Math.random() * 9);

      // Get the line from the list of available lines and remove it from that list
      const j = freeRowsIndicies[randomIndex];
      freeRowsIndicies.splice(freeRowsIndicies, randomIndex);

      // Add to the specific line
      for (let i = 0; i < randomSize; i++) {
        grid[j][i] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
      }
    }
  }

  return grid;
};
