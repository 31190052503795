import styles from './Controls.module.scss';
import React, { useContext, useEffect, useState } from 'react';
import GameStateContext from './GameStateContext';
import { EMPTY } from './objects/constants';
import { useTour } from '@reactour/tour';
//import SoundContext from './SoundContext';
import { switchFullScreen } from './util';

const Controls = () => {
  const gameState = useContext(GameStateContext);
  const [stats, setStats] = gameState.statsState;
  const [, setGrid] = gameState.gridState;
  const { isOpen, setIsOpen, setCurrentStep } = useTour();
  const [isRestartBarVisible, setIsRestartBarVisible] = useState(false);
  const [difficulty, setDifficulty] = useState('M');
  //const sounds = useContext(SoundContext);

  const onPauseClick = () => {
    setStats(prevStats => ({
      ...prevStats,
      isPaused: !prevStats.isPaused,
    }));
  };

  const onRestartClick = () => {
    setIsRestartBarVisible(true);
  };

  const onRestartOptionClick = d => {
    localStorage.setItem('difficulty', d);
    setGrid(() => {
      return Array(10)
        .fill(null)
        .map(() => Array(20).fill(EMPTY));
    });
    setStats({
      score: 0,
      isPaused: false,
      isTour: false,
      isGameover: false,
      difficulty: d,
      mode: false,
    });
    setIsRestartBarVisible(false);
    //sounds.theme.stop();
  };

  const closeTour = () => {
    setIsOpen(false);
    setStats(prevStats => ({
      ...prevStats,
      isTour: false,
    }));
  };

  const onTourClick = () => {
    setCurrentStep(0);
    setIsOpen(true);
    setStats(prevStats => ({
      ...prevStats,
      isTour: true,
    }));
  };

  const onModeClick = () => {
    setStats(prevStats => ({
      ...prevStats,
      mode: true,
    }));
    switchFullScreen(true);
  }

  const handleKeyDown = event => {
    const key = event.key.toUpperCase();

    switch (key) {
      case 'К': // russian
      case 'R':
        onRestartClick();
        break;
      case 'З': // russian
      case 'P':
        onPauseClick();
        break;
      case 'Е': // russian
      case 'T':
        // Handle T key press
        onTourClick();
        break;
      case 'Ь': // russian
      case 'M':
        onModeClick();
        break;
      case 'Ф': // russian
      case 'A':
        // Handle A key press
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // must be present - when user clicks outside the tour, it will close restore the state
    if (!isOpen) {
      closeTour();
    }

    // TODO: Uncomment on prod deploy. Do not show tour for now, so we can share the game
    // as file(s)
    if (localStorage.getItem('first_visit') !== 'false') {
      localStorage.setItem('first_visit', 'false');
      onTourClick();
    }

    if (!localStorage.getItem('difficulty')) {
      localStorage.setItem('difficulty', 'E');
      setDifficulty('E');
    } else {
      setDifficulty(localStorage.getItem('difficulty'));
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <div className={styles.controls}>
      <ul>
        <li>
          <span className={styles.control} onClick={onRestartClick}>
            <span className={styles.underline}>R</span>estart
          </span>
        </li>
        <li>
          <span
            className={`${styles.control} ${
              stats.isPaused ? styles.blinking : ''
            }`}
            onClick={onPauseClick}
          >
            <span className={styles.underline}>P</span>ause
          </span>
        </li>
        <li>
          <span className={styles.control} onClick={onModeClick}>
            <span className={styles.underline}>M</span>ode
          </span>
        </li>
        <li>
          <span className={styles.control} onClick={onTourClick}>
            <span className={styles.underline}>T</span>our
          </span>
        </li>
      </ul>
      {isRestartBarVisible && (
        <div className={styles.restartBar}>
          <ul>
            <li>
              <span
                onMouseEnter={() => setDifficulty('E')}
                onClick={() => onRestartOptionClick('E')}
                className={`${styles.control} ${
                  difficulty === 'E' ? styles.blinking : ''
                }`}
              >
                <span className={styles.underline}>E</span>ASY
              </span>
            </li>
            <li>
              <span
                onMouseEnter={() => setDifficulty('M')}
                onClick={() => onRestartOptionClick('M')}
                className={`${styles.control} ${
                  difficulty === 'M' ? styles.blinking : ''
                }`}
              >
                <span className={styles.underline}>M</span>EDIUM
              </span>
            </li>
            <li>
              <span
                onClick={() => onRestartOptionClick('H')}
                onMouseEnter={() => setDifficulty('H')}
                className={`${styles.control} ${
                  difficulty === 'H' ? styles.blinking : ''
                }`}
              >
                <span className={styles.underline}>H</span>ARD
              </span>
            </li>
          </ul>
        </div>
      )}
      {isRestartBarVisible ? null : (
        <>
          {stats.isGameover ? (
            <div className={styles.statusGameover}>* GAME OVER *</div>
          ) : (
            <div className={styles.status}>MAKE TO TEN</div>
          )}
        </>
      )}
    </div>
  );
};

export default Controls;
