import { useEffect } from 'react';
import { isGameover } from './objects/gameEngine';

export const useGameoverEffect = (stats, grid, setStats) => {
  useEffect(() => {
    const f = () => {
      if (stats.isPaused || stats.isTour || stats.isGameover) return;

      if (isGameover(grid)) {
        setStats(prevStats => ({
          ...prevStats,
          isGameover: true,
        }));
      }
    };
    const timer = setInterval(f, 1000);
    f();

    // Clean up the timer when the component is unmounted
    return () => {
      clearInterval(timer);
    };
  }, [stats, grid]);
};
