import { useState, useEffect, useContext } from 'react';
import GameStateContext from './GameStateContext';

const useResponsiveSize = () => {
  const [size, setSize] = useState({ width: 0, height: 0, hasSpaceForMode: false });
  const gameState = useContext(GameStateContext);
  const [stats] = gameState.statsState;

  useEffect(() => {
    const updateSize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // if mode is on, we should not care about footer, just expand as much as you can
      const footerAdjustment = stats.mode ? -45 : -115;

      const x = screenWidth / 2 - 50;
      const y = screenHeight + footerAdjustment;

      const hasSpaceForMode = (screenWidth - Math.min(x, y) * 2) / 2 > 80;

      setSize({ width: Math.min(x, y), height: Math.min(x, y), hasSpaceForMode: hasSpaceForMode });
    };

    updateSize();

    window.addEventListener('resize', updateSize);

    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, [stats.mode]);

  return size;
};

export default useResponsiveSize;
