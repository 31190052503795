import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { updateOnTick } from './objects/gameEngine';
import { getLevelInfo } from './objects/levelEngine';
import { useGameoverEffect } from './useGameoverEffect';
import { useContext } from 'react';
import SoundContext from './SoundContext';

import {
  EMPTY,
  BALL_TYPE_COMPUTER,
  BALL_FLAG_MOVING,
} from './objects/constants';

const GameStateContext = React.createContext();

export const GameStateProvider = ({ children }) => {
  const gridState = useState(() => {
    const initialArray = Array(10)
      .fill(null)
      .map(() => Array(20).fill(EMPTY));

    initialArray[0][0] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    initialArray[0][1] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    initialArray[0][2] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;

    // initialArray[0][18] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[0][17] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[0][16] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[0][15] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[0][14] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[0][13] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;

    // initialArray[1][18] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[1][17] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[1][16] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[1][15] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[1][14] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[1][13] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;

    // initialArray[2][18] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[2][17] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[2][16] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[2][15] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[2][14] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[2][13] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;

    // initialArray[3][18] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[3][17] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[3][16] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[3][15] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[3][14] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[3][13] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;

    // initialArray[4][18] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[4][17] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[4][16] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[4][15] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[4][14] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[4][13] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;

    // initialArray[5][18] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[5][17] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[5][16] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[5][15] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[5][14] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[5][13] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;

    // initialArray[7][18] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[7][17] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[7][16] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[7][15] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[7][14] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[7][13] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;

    // initialArray[9][18] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[9][17] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[9][16] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[9][15] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[9][14] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;
    // initialArray[9][13] = BALL_TYPE_COMPUTER | BALL_FLAG_MOVING;

    return initialArray;
  });

  const statsState = useState({
    score: 0,
    isPaused: false,
    isTour: false,
    isStarted: false,
    isGameover: false,
    difficulty: localStorage.getItem('difficulty') || 'E',
  });

  const [stats, setStats] = statsState;
  const [grid] = gridState;

  const gameState = {
    gridState,
    statsState,
  };

  const sounds = useContext(SoundContext);
  // Do some magic with tick, so it sounds better. We need state here
  // But in the effect we don't want to use state, use variable instead,
  // so the game is faster - we won't need to set interval on every state update.
  const [initialTick, setInitialTick] = useState(true);

  useEffect(() => {
    const [, setGrid] = gridState;
    let cnt = 0;
    // Optimization described above, so we don't need to set interval on every
    // tick value update.
    let tick = { value: initialTick };

    console.log('Setting interval...');
    const f = () => {
      if (stats.isPaused || stats.isTour || stats.isGameover) return;

      const levelInfo = getLevelInfo(stats.score, stats.difficulty);
      setGrid(prevGrid => updateOnTick(cnt, prevGrid, levelInfo, sounds, tick));
      cnt = cnt + 1;
    };
    const timer = setInterval(f, 1000 / 20);
    f();

    // Clean up the timer when the component is unmounted
    return () => {
      console.log('Clearing interval...');
      clearInterval(timer);
      setInitialTick(!initialTick);
    };
  }, [stats]);

  useGameoverEffect(stats, grid, setStats);

  return (
    <GameStateContext.Provider value={gameState}>
      {children}
    </GameStateContext.Provider>
  );
};

GameStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GameStateContext;
