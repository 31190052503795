import React, { useContext } from 'react';
import GameStateContext from './GameStateContext';
import { useTour } from '@reactour/tour';

const CloseTourButton = () => {
  const gameState = useContext(GameStateContext);
  const [, setStats] = gameState.statsState;
  const { setIsOpen } = useTour();

  const handleCloseTour = () => {
    setIsOpen(false);
    setStats(prevStats => ({
      ...prevStats,
      isTour: false,
    }));
  };

  return <span onClick={handleCloseTour}>Close the tour</span>;
};

export default CloseTourButton;
