import React, { useContext } from 'react';
import styles from './Field.module.scss';
import GameStateContext from './GameStateContext';
import {
  BALL_TYPE_COMPUTER,
  EMPTY,
  BALL_FLAG_DISAPPEARING,
} from './objects/constants';
import useResponsiveSize from './useResponsiveSize';
import Mode from './Mode';

const Field = () => {
  const fieldSize = useResponsiveSize();

  const gameState = useContext(GameStateContext);
  const [stats] = gameState.statsState;
  const [grid] = gameState.gridState;

  const renderCells = () => {
    const cells = [];
    for (let j = 0; j < 10; j++) {
      for (let i = 0; i < 10; i++) {
        const k = i + j * 10;
        const ballType = grid[j][i] & 0b00001111;
        const isDisappearing = (grid[j][i] & BALL_FLAG_DISAPPEARING) !== 0;

        if (ballType === BALL_TYPE_COMPUTER) {
          cells.push(
            <div className={styles.cell} key={k}>
              <div
                className={`${styles.ballType1} ${
                  isDisappearing && styles.explosion
                }`}
                key={k}
              ></div>
            </div>
          );
        } else if (ballType === EMPTY) {
          cells.push(<div className={styles.cell} key={k}></div>);
        } else {
          cells.push(
            <div className={styles.cell} key={k}>
              ?
            </div>
          );
        }
      }
    }
    return cells;
  };

  return (
    <div
      className={styles.field}
      style={{ width: fieldSize.width, height: fieldSize.height }}
    >
      <div className={`${styles.cellContainer} tour-first-step`}>
        {renderCells()}
      </div>
      {stats.mode && (
        <div className={styles.modeContainer} style={fieldSize.hasSpaceForMode ? { left: '-80px', textAlign: 'right' } : {}}>
          <Mode />
        </div>
      )}
    </div>
  );
};

export default Field;
