import React, { useState, useContext, useMemo } from 'react';
import styles from './Board.module.scss';
import GameStateContext from './GameStateContext';
import SoundContext from './SoundContext';
import { handleClick, removeDisappearingIfNeeded } from './objects/gameEngine';
import {
  EMPTY,
  BALL_TYPE_COMPUTER,
  BALL_TYPE_HUMAN,
  BALL_FLAG_DISAPPEARING,
} from './objects/constants';
import { useGameLifecycle } from './useGameLifecycle';
import useResponsiveSize from './useResponsiveSize';

const Board = () => {
  const boardSize = useResponsiveSize();
  const [hoveredCellIndex, setHoveredCellIndex] = useState(null);
  const [hoveredCellRow, setHoveredCellRow] = useState(null);
  const gameState = useContext(GameStateContext);
  const sounds = useContext(SoundContext);
  useGameLifecycle(gameState.statsState); //, sounds);

  const [grid, setGrid] = gameState.gridState;

  const onCellClick = (j, i) => {
    const newGrid = handleClick(j, i, grid, gameState.statsState, sounds);
    setGrid(newGrid);

    setTimeout(() => {
      setGrid(prevGrid => removeDisappearingIfNeeded(prevGrid));
    }, 500);
  };

  const isTouchDevice = window.matchMedia('(hover: none)').matches;

  const renderBoard = useMemo(() => {
    const board = [];
    const rows = [];

    for (let j = 0; j < 10; j++) {
      const row = [];
      const hasNonZeroValuesAfterIndex = grid[j].some(
        (value, index) => index > (hoveredCellIndex || 0) && value !== EMPTY
      );

      for (let i = 10; i < 20; i++) {
        const k = i + (j + 1) * 10;
        const ballType = grid[j][i] & 0b00001111;
        const isDisappearing = (grid[j][i] & BALL_FLAG_DISAPPEARING) !== 0;

        switch (ballType) {
          case BALL_TYPE_COMPUTER: {
            const className = `${styles.ballType1} ${styles.ballContainer} ${
              isDisappearing && styles.explosion
            }`;
            row.push(
              <div className={styles.cell} key={k}>
                <div className={className} key={k}></div>
              </div>
            );
            break;
          }
          case BALL_TYPE_HUMAN: {
            const className = `${styles.ballType2} ${
              isDisappearing && styles.explosion
            }`;
            row.push(
              <div className={styles.cell} key={k}>
                <div className={className} key={k}></div>
              </div>
            );
            break;
          }
          case EMPTY: {
            const isHover =
              !hasNonZeroValuesAfterIndex &&
              hoveredCellIndex <= i &&
              hoveredCellRow === j;
            const className = `${styles.cell} ${isHover ? styles.hover : ''}`;
            row.push(
              <div
                className={className}
                key={k}
                onClick={() => onCellClick(j, i)}
                onMouseEnter={() => {
                  if (isTouchDevice) return;
                  setHoveredCellIndex(i);
                  setHoveredCellRow(j);
                }}
                onMouseLeave={() => {
                  if (isTouchDevice) return;
                  setHoveredCellIndex(null);
                  setHoveredCellRow(null);
                }}
              ></div>
            );
            break;
          }
          default: {
            row.push(
              <div className={styles.cell} key={k}>
                {grid[j][i]}
              </div>
            );
            break;
          }
        }
      }
      rows.push(row);
    }

    for (let j = 0; j < rows.length; j++) {
      const row = rows[j];
      board.push(
        <div className={`${styles.row}`} key={j}>
          {row}
        </div>
      );
    }

    return board;
  }, [grid, hoveredCellIndex, hoveredCellRow, isTouchDevice]);

  return (
    <div
      className={styles.board}
      style={{ width: boardSize.width, height: boardSize.height }}
    >
      <div className={`${styles.cellContainer} tour-second-step`}>
        <div
          className={`${styles.tourThirdStep} tour-third-step`}
          style={{ width: boardSize.width / 10 - 10 }}
        ></div>
        {renderBoard}
      </div>
    </div>
  );
};

export default Board;

