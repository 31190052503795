const getEasyLevelInfo = score => {
  if (score >= 0 && score <= 2) {
    return { level: 1, maxNumberOfElements: 1, speedFactor: 20 };
  } else if (score > 2 && score <= 10) {
    return { level: 1, maxNumberOfElements: 3, speedFactor: 20 };
  } else {
    return {
      level: Math.floor((score - 10) / 20) + 2,
      maxNumberOfElements: 5,
      speedFactor: 20,
    };
  }
};

const getMediumLevelInfo = score => {
  if (score >= 0 && score <= 2) {
    return { level: 1, maxNumberOfElements: 1, speedFactor: 20 };
  } else if (score > 2 && score <= 10) {
    return { level: 1, maxNumberOfElements: 5, speedFactor: 20 };
  } else if (score > 10 && score <= 20) {
    return { level: 2, maxNumberOfElements: 5, speedFactor: 15 };
  } else if (score > 20 && score <= 30) {
    return { level: 3, maxNumberOfElements: 10, speedFactor: 15 };
  } else if (score > 30 && score <= 45) {
    return { level: 4, maxNumberOfElements: 10, speedFactor: 10 };
  } else if (score > 45 && score <= 65) {
    return { level: 5, maxNumberOfElements: 10, speedFactor: 10 };
  } else if (score > 65 && score <= 90) {
    return { level: 6, maxNumberOfElements: 10, speedFactor: 10 };
  } else if (score > 90 && score <= 130) {
    return { level: 7, maxNumberOfElements: 10, speedFactor: 8 };
  } else if (score > 130 && score <= 180) {
    return { level: 8, maxNumberOfElements: 10, speedFactor: 8 };
  } else if (score > 180 && score <= 240) {
    return { level: 9, maxNumberOfElements: 10, speedFactor: 8 };
  } else if (score > 240 && score <= 315) {
    return { level: 10, maxNumberOfElements: 10, speedFactor: 8 };
  } else if (score > 315 && score <= 405) {
    return { level: 11, maxNumberOfElements: 5, speedFactor: 5 };
  } else if (score > 405 && score <= 510) {
    return { level: 12, maxNumberOfElements: 10, speedFactor: 5 };
  } else if (score > 510 && score <= 630) {
    return { level: 13, maxNumberOfElements: 10, speedFactor: 4 };
  } else if (score > 630 && score <= 765) {
    return { level: 14, maxNumberOfElements: 10, speedFactor: 4 };
  } else {
    return {
      level: Math.floor((score - 765) / 20) + 15,
      maxNumberOfElements: 10,
      speedFactor: 4,
    };
  }
};

const getHardLevelInfo = score => {
  if (score >= 0 && score <= 2) {
    return { level: 1, maxNumberOfElements: 1, speedFactor: 15 };
  } else if (score > 2 && score <= 10) {
    return { level: 1, maxNumberOfElements: 5, speedFactor: 15 };
  } else if (score > 10 && score <= 20) {
    return { level: 2, maxNumberOfElements: 10, speedFactor: 10 };
  } else if (score > 20 && score <= 30) {
    return { level: 3, maxNumberOfElements: 10, speedFactor: 8 };
  } else if (score > 30 && score <= 45) {
    return { level: 4, maxNumberOfElements: 10, speedFactor: 7 };
  } else if (score > 45 && score <= 65) {
    return { level: 5, maxNumberOfElements: 10, speedFactor: 6 };
  } else if (score > 65 && score <= 90) {
    return { level: 6, maxNumberOfElements: 10, speedFactor: 5 };
  } else if (score > 90 && score <= 120) {
    return { level: 7, maxNumberOfElements: 10, speedFactor: 4 };
  } else {
    return {
      level: Math.floor((score - 120) / 20) + 8,
      maxNumberOfElements: 10,
      speedFactor: 4,
    };
  }
};

export function getLevelInfo(score, difficulty) {
  if (difficulty === 'M') {
    return getMediumLevelInfo(score);
  } else if (difficulty === 'H') {
    return getHardLevelInfo(score);
  } else {
    return getEasyLevelInfo(score);
  }
}
