import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Howl } from 'howler';

const SoundContext = React.createContext();

export const SoundProvider = ({ children }) => {
  const [sounds] = useState({
    sprite: new Howl({
      src: ['audio/sounds.mp3', 'audio/sounds.webm'],
      sprite: {
        empty: [0, 2000], // starts at 0s, lasts for 2s. Required on start to load the sprite
        tick2: [12000, 2000], // starts at 12s, lasts for 2s
        tick1: [10000, 2000], // starts at 10s, lasts for 2s
        // tick2: [6000, 2000], // starts at 6s, lasts for 2s
        // tick1: [8000, 2000], // starts at 8s, lasts for 2s
        success: [14000, 2000], // starts at 14s, lasts for 2s
        failure: [16000, 2000],  // starts at 16s, lasts for 2s
        onten: [18000, 2000], // starts at 18s, lasts for 2s
      }
    })
  });

  // Play as soon as we can to load those sounds
  sounds.sprite.play('empty');

  return (
    <SoundContext.Provider value={sounds}>{children}</SoundContext.Provider>
  );
};

SoundProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SoundContext;
